// animation
TweenMax.to('.spin_right', 30, {rotation:360, transformOrigin:"50% 50%", repeat:100, ease: Power0.easeNone});

TweenMax.to('.spin_left', 30, {rotation:-360, transformOrigin:"50% 50%", repeat:100, ease: Power0.easeNone});

$(document).ready(function () {
    $('.newsletter-submit').click(function() {
      checked = $("input[type=checkbox]:checked").length;
      var element = $(".checkbox-custom")[0];

      if(!checked) {
        element.setCustomValidity("This field cannot be left blank");
      } else {
        element.setCustomValidity("");
      }

    });
});

// nav
$(".burger").on("click", function() {
    $(".nav ul").toggleClass("show");

    $(this).toggleClass('close');
});

// search
// $(".search_button").on("click", function() {
//     $(".searchbar").toggleClass("show");
//     $(this).toggleClass('hide');
// });

// ACCORDION NAV
jQuery(document).ready(function(){
	var accordionsMenu = $('.cd-accordion-menu');

	if( accordionsMenu.length > 0 ) {
		accordionsMenu.each(function(){
			var accordion = $(this);
			//detect change in the input[type="checkbox"] value
			accordion.on('change', 'input[type="checkbox"]', function(){
				var checkbox = $(this);
				// console.log(checkbox.prop('checked'));
				( checkbox.prop('checked') ) ? checkbox.siblings('div').attr('style', 'display:none;').slideDown(300): checkbox.siblings('div').attr('style', 'display:block;').slideUp(300);
				( checkbox.prop('checked') ) ? checkbox.siblings('label').addClass('active'): checkbox.siblings('label').removeClass('active');
			});
		});
	}
});

// sticky nav
var $el = $('.nav');
var isPositionFixed = ($el.css('position') == 'fixed');
var stickyHeight = ($('.nav-height').outerHeight() + 22);

$(window).scroll(function(e) {

    if ($(this).scrollTop() > stickyHeight) {
        $('.blue_nav').addClass('sticky');
        $('.logo').addClass('sticky');
        $('.nav').addClass('sticky');
        // $('.nav-text').addClass('hide');
        // $('.hidden_nav').addClass('height-fix');
    }

    if ($(this).scrollTop() < stickyHeight) {
        $('.blue_nav').removeClass('sticky');
        $('.logo').removeClass('sticky');
        $('.nav').removeClass('sticky');
        // $('.nav-text').removeClass('hide');
        // $('.hidden_nav').removeClass('height-fix');
    }
});

// cookies popup
window.addEventListener("load", function(){
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#26344c"
            },
            "button": {
                "background": "#00a1be"
            }
        },
        "content": {
            "message": "We have updated our Privacy and Cookies Policy. By using this site you agree to our updated policy. To find out more see our",
            "link": "privacy and cookies policy.",
            "dismiss": "I understand",
            "href": "../privacy-and-cookies-policy.php"
        }
    })
});

// Anchor tag JS
$(function() {
    $('a[href*=#]:not([href=#])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top-stickyHeight-10
                }, 1000);
                return false;
            }
        }
    });
});

$(".gotolocation").on('click', function() {
    $('html, body').animate({
        scrollTop: $(".branch_locator").offset().top-stickyHeight-10
    }, 2000);

    // branch map link fix
    setTimeout(function() {
        $('.link-branch').each(function() {
            var orig = $(this).attr('href');
            orig = orig.replace('Newcastle Upon Tyne', 'newcastle');
            var lowered = orig.toLowerCase();

            $(this).attr('href', lowered);
        });
    }, 1000);
});


// career file uploads
$('.file1').on('change', function() {
    var filename = $(this).val().replace(/C:\\fakepath\\/i, '')
    $('.file-name1').html(filename);
});

$('.file2').on('change', function() {
    var filename = $(this).val().replace(/C:\\fakepath\\/i, '')
    $('.file-name2').html(filename);
});

$('.js--submit').on('click', function() {
    var val = $('.file1').val();
    var val2 = $('.file2').val();


    if (val == '') {
       $('.coverletter').css('display', 'block');
    }

    if (val2 == '') {
       $('.cv').css('display', 'block');
    }
});


// video play overlay
// $('.home_video').on('click', function() {
//     if($(this).hasClass('playing')) {
//         $(this).removeClass('playing');
//         $('#video').get(0).pause();
//         $('.play').removeClass('hide');
//     } else {
//         $(this).addClass('playing');
//         $('#video').get(0).play();
//         $('.play').addClass('hide');
//     }
// });

// $('.video').parent().click(function () {
//     if($(this).children(".video").get(0).paused){
//         $(this).children(".video").get(0).play();
//         $(this).children(".play").fadeOut();
//     }else{
//        $(this).children(".video").get(0).pause();
//         $(this).children(".play").fadeIn();
//     }
// });


// NEW BLOG FILTERING
function eventFilter(){
  var sections = $('.blog--item');
  function updateContentVisibility(){
      var checked = $(".filter-button-group :checkbox:checked");
      if(checked.length){
          sections.hide();
          checked.each(function(){
              $("." + $(this).val()).show();
          });
      } else {
          sections.show();
      }
  }

  $(".filter-button-group :checkbox").click(updateContentVisibility);
  updateContentVisibility();
};
eventFilter();
//

// FADE IN ITEMS IN RANDOM ORDER
var v = $(".grid-item").css('visibility', 'hidden'), cur = 0;
for(var j, x, i = v.length; i; j = parseInt(Math.random() * i), x = v[--i], v[i] = v[j], v[j] = x);
function fadeInNextLI() {
  v.eq(cur++).css('visibility','visible').hide().fadeIn();
  if(cur != v.length) setTimeout(fadeInNextLI, 50);
}
fadeInNextLI();

// Isotope filtering - from button and url

function getHashFilter() {
    // get filter=filterName
    var matches = location.hash.match( /filter=([^&]+)/i );
    var hashFilter = matches && matches[1];
    return hashFilter && decodeURIComponent( hashFilter );
}

// Blog packery
pckry2 = $('.grid').isotope({
    // options
    itemSelector: '.grid-item',
    stamp: '.stamp',
    layoutMode: 'packery',
    packery: {
        gutter: 7
    }
});

// filter items on button click
var filterValue;
$('.filter-button-group').on( 'click', 'button', function() {
    filterValue = $(this).attr('data-filter');
    $grid.isotope({ filter: filterValue });
});

// init Isotope
var $grid = $('.grid');

// bind filter button click
var $filterButtonGroup = $('.filter-button-group');
$filterButtonGroup.on( 'click', 'button', function() {

    if($(this).hasClass('is-checked')) {
        $('.all').trigger( "click" );
        $grid.isotope({ filter: filterValue });
    } else {
        var filterAttr = $( this ).attr('data-filter');
        // set filter in hash
        location.hash = 'filter=' + encodeURIComponent( filterAttr );
    }
});

var isIsotopeInit = false;

function onHashchange() {
    var hashFilter = getHashFilter();
    if ( !hashFilter && isIsotopeInit ) {
        return;
    }

    isIsotopeInit = true;

    // set selected class on button
    if ( hashFilter ) {
        $filterButtonGroup.find('.is-checked').removeClass('is-checked');
        $filterButtonGroup.find('[data-filter="' + hashFilter + '"]').addClass('is-checked');
    }
}

$(window).on( 'hashchange', onHashchange );

// trigger event handler to init Isotope
onHashchange();

$(document).on("scroll",function(){
    if($(document).scrollTop()>50){
        $(".to_top").removeClass("hide").addClass("show");
    } else{
        $(".to_top").removeClass("show").addClass("hide");
    }
});

if($('.current_cat ul li').length == 1) {
    $('.current_cat ul li').addClass('no-comma');
}

var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
// if(width > 767) {
//   hummingbirdAnimation();
//   hummingbirdSlides();
//   pfpAnimation();
//   pfpSlides();
// }
//

// MYLEBC ACCORDIONS
$('.js--accordion').on('click', function(){

if($(this).children().hasClass('open')) {
    $(this).children().removeClass('open');
  } else {
    // $('.accord-title').removeClass('open');
    // $('.accord-content').removeClass('open');
    $(this).children().addClass('open')
  }
});
//

// MYLEBC AOS
var height = (window.innerHeight > 0) ? window.innerHeight : screen.height;
if(height < 850) {
  AOS.init({
    offset: -120
  });
} else {
  AOS.init();
}
//

$(function() {
  $("#form3_datepicker").datepicker({
    beforeShowDay: $.datepicker.noWeekends,
    firstDay: 1,
    dateFormat: 'dd/mm/yy',
  });
});
